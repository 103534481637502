<template>
  <div style="height: 100vh; width: 100%; background-color:#e7e7e7; overflow-y: hidden;">
    <div
      v-if="loading"
      class="loader"
    ></div>
    <div v-else>
      <div
        class=""
        style="padding: 10px;"
      >
        <v-img
          :src="getInstallLogo()"
          max-height="60px"
          max-width="250px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </div>
      <div
        style="display: flex;
      width: 100%;
      height: calc(100vh - 70px);
      vertical-align: middle;
      align-items: center;
      justify-content: center;"
      >
        <a
          v-if="!disconnect"
          :href="connectUrl"
          style="padding: 10px;background-color: #fff;"
          target="_blank"
        >
          <qrcode-vue
            :value="connectUrl"
            :size="qrCodeSize"
          />
        </a>
        <a
          v-else
          :href="disconnectUrl"
          style="padding: 10px;background-color: #fff;"
        >
          <qrcode-vue
            :value="disconnectUrl"
            :size="qrCodeSize"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import themeConfig from '@themeConfig'
import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      token: null,
      disconnect: false,
      loading: true,
      qrCodeSize: 300,
    }
  },
  computed: {
    connectUrl() {
      return `${window.location.origin}/install/${this.token}/connect`
    },
    disconnectUrl() {
      return `${window.location.origin}/install/${this.token}/disconnect`
    },
  },
  async mounted() {
    this.qrCodeSize = (window.innerHeight - 70) * 0.7
    if (localStorage.bue_ds_screen_token && localStorage.bue_ds_screen_token !== 'undefined') {
      const retrieveData = async () => {
        try {
          await axios.get(`/displays/by-token/${this.token}`)
          this.token = localStorage.bue_ds_screen_token
          this.disconnect = true
          this.loading = false
        } catch (error) {
          this.loading = false
          this.disconnect = false
          if (error.response && error.response.status === 404) {
            localStorage.removeItem('bue_ds_screen_token')
            this.$cookies.remove('bue_ds_screen_token')
            axios.get('/displays/token').then(response => {
              if (response.data.success) {
                this.token = response.data.data
              } else {
                console.log(`Error: ${response.data.message}`)
              }
            }, e => {
              console.log(`Error: ${e}`)
            })
          }
        }
      }
      await retrieveData()
    } else {
      axios.get('/displays/token').then(response => {
        this.loading = false
        if (response.data.success) {
          this.token = response.data.data
        } else {
          console.log(`Error: ${response.data.message}`)
        }
      }, error => {
        this.loading = false
        console.log(`Error: ${error}`)
      })
    }

    this.redirectIfInstalled()
  },
  methods: {
    redirectIfInstalled() {
      setInterval(() => {
        if (this.token) {
          axios.get(`/displays/by-token/${this.token}`).then(response => {
            const { data: display } = response.data
            if (display && display.id) {
              localStorage.setItem('bue_ds_screen_token', this.token)
              this.$cookies.set('bue_ds_screen_token', this.token, Infinity)
              router.push({ name: 'public-display' })
            }
          }, error => {
            console.log(`Error: ${error}`)
          })
        }
      }, 5000)
    },
    getInstallLogo() {
      if (this.disconnect) {
        return require('@/assets/images/png/BuE DS Deinstallation.png')
      }

      return require('@/assets/images/png/BuE DS Logo public Installation.png')
    },
  },
  setup() {
    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },

}
</script>

<style scoped lang="scss">
.loader {
  border: 16px solid #444444;
  border-radius: 50%;
  border-top: 16px solid #d278c9;
  width: 120px;
  height: 120px;
  -webkit-animation: spin-data-v-66ef5422 2s linear infinite;
  animation: spin-data-v-66ef5422 2s linear infinite;
  z-index: 10;
  position: fixed;
  top: 40%;
  left: 50%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
